import { remove } from 'api/common';
import PrivateRoute from 'components/PrivateRoute';

import t from 'l10n';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { clearSession, SESSION_STORE_KEY } from 'util/fetch.util';
import './App.scss';

import MenuArea from './components/MenuArea';

import features from './features';
import FeatureInfoPage from './pages/feature/FeatureInfoPage';
import LoginPage from './pages/login/LoginPage';

import MainPage from './pages/main/MainPage';
import RiotBarLoader from '@riotgames/riotbar-react';

const configs = {
  selfAuthEnv: process.env.REACT_APP_SELF_AUTH_ENV,
  edgeApi: process.env.REACT_APP_SHUT_DOWN_EDGE_URL,
};

class App extends Component {
  logout() {
    remove(configs, '/parents/v1/session/token', () => clearSession('/')).catch(() => clearSession('/'));
  }

  render() {
    const authConfigs = {
      ...configs,
      didLogin: () => sessionStorage.setItem(SESSION_STORE_KEY, `${new Date().getTime()}`),
      isInLogin: () => sessionStorage.getItem(SESSION_STORE_KEY) !== null,
    };

    const menuInfos = features.map(feature => ({ name: t(feature.label), route: feature.feature }));
    const routes = [];
    features.map(feature => {
      routes.push(
        <PrivateRoute
          key={routes.length}
          path={`/${feature.feature}/:route?`}
          authRequired={feature.authRequired}
          isInLogin={authConfigs.isInLogin()}
          component={props => (
            <FeatureInfoPage configs={authConfigs} info={feature} selected={feature.default} {...props} />
          )}
        />,
      );
      return routes;
    });

    return (
      <div className="App">
        <Router basename={'/'}>
          <div>
            <MenuArea menus={menuInfos} isInLogin={() => authConfigs.isInLogin()} logout={this.logout.bind(this)} />
            <Switch>
              <Route exact path={'/'} render={props => <MainPage configs={authConfigs} {...props} />} />
              <Route path={'/login'} render={props => <LoginPage configs={authConfigs} {...props} />} />
              {routes}
            </Switch>
          </div>
        </Router>
        <RiotBarLoader product="kr-riotgames" environment={process.env.REACT_APP_RIOTBAR_ENV} locale="ko_KR" />
      </div>
    );
  }
}

export default App;
