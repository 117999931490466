import t from 'l10n';

export const SESSION_STORE_KEY = 'login-timestamp';

export const clearSession = (redirectPath) => {
  sessionStorage.removeItem(SESSION_STORE_KEY);
  window.location.href = redirectPath;
};

export function commonErrorHandler(response) {
  if (response &&
    (response.ok)) {
    return response;
  }

  if (response && response.status === 401) {
    clearSession('/login');
    alert(t('login.expire'));
  }

  throw new UserException('Request failure', response.status);
}

function UserException(message, status) {
  this.message = message;
  this.status = status;
}
