import { commonErrorHandler } from 'util/fetch.util';

export const query = (configs, uri, payloadHandler) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    credentials: 'include',
    cache: 'no-cache',
    mode: 'cors',
  }).then(commonErrorHandler)
    .then(response => response.ok ? response.json() : Error('Error in getting PI'))
    .then(payload => payloadHandler(payload))
    .catch(() => payloadHandler(null))

export const remove = (configs, uri, handler = () => {
}) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    credentials: 'include',
    cache: 'no-cache',
    mode: 'cors',
  }).then(commonErrorHandler).then(() => handler());

export const put = (configs, uri, payload, handler) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
    cache: 'no-cache',
    mode: 'cors',
  }).then(commonErrorHandler).then(() => handler());

export const post = (configs, uri, payload, handler, noContent = false) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'Application/json; charset=UTF-8',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
    cache: 'no-cache',
    mode: 'cors',
  }).then(commonErrorHandler).then(response => noContent ? null : response.json())
    .then(responseBody => handler(responseBody));

export const form = (configs, uri, payload, handler = () => {}) =>
  fetch(`${configs.edgeApi}${uri}`, {
    method: 'POST',
    credentials: 'include',
    body: payload,
    cache: 'no-cache',
    mode: 'cors',
  }).then(commonErrorHandler).then(() => handler())

export const buildFormData = (json) => {
  const formData = new FormData();
  Object.keys(json).map(key => {
    const value = json[key];
    return formData.append(key, value.file ? value.file : value)
  });
  return formData;
};
