import { startIdentityAuth } from '@riotgames/kr-identity-sdk';
import { post } from 'api/common';
import classNames from 'classnames';
import BorderBoxButton from 'components/BorderBoxButton';
import { fetchTextFileFromUrl } from 'components/FileDownloader';

import t from 'l10n';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './LoginPage.scss';

class LoginPage extends Component {
  static propTypes = {
    configs: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      selfAuthPopup: null,
      piConfirmed: false,
      piAgreement: '',
      piConsignmentAgreement: '',
      transferConfirmed: false,
      transferAgreement: '',
      message: null,
    };
  }

  componentDidMount() {
    this.fetchAgreementTextFiles();
  }

  fetchAgreementTextFiles() {
    fetchTextFileFromUrl(t('login.agreement.pi.link'), text => this.setState({ piAgreement: text }));

    fetchTextFileFromUrl(t('login.agreement.pi.consignment.link'), text =>
      this.setState({ piConsignmentAgreement: text }),
    );

    fetchTextFileFromUrl(t('login.agreement.transfer.login.link'), text => this.setState({ transferAgreement: text }));
  }

  onLoginSuccess({ identityToken }) {
    const { configs } = this.props;
    const payload = { selfAuthToken: identityToken };
    post(configs, '/parents/v1/session/token', payload, response =>
      setTimeout(() => {
        configs.didLogin(response.realName);
        window.location.href = '/';
      }, 200),
    );
  }

  onLoginFailure(error) {
    alert(error);
  }

  loginWith() {
    const { configs } = this.props;
    const { piConfirmed, transferConfirmed } = this.state;

    if (piConfirmed && transferConfirmed) {
      this.setState({ message: null });
      startIdentityAuth({
        serviceType: 'PARENTAL_PORTAL',
        environment: configs.selfAuthEnv,
        isPopup: true, // false for mobile and provide callbackUrl for redirecting
        successCallback: this.onLoginSuccess.bind(this), // optional, specify callback handler/function if the isPopup = true
        failCallback: this.onLoginFailure.bind(this), // optional, specify callback handler/function if the isPopup = true
      });
    } else {
      this.setState({ message: t('login.check') });
    }
  }

  render() {
    const { history } = this.props;
    const { message, piConfirmed, transferConfirmed } = this.state;

    return (
      <div className={'login-page'}>
        <div className={'page-desc'}>
          <div className={'page-image'}>
            <img alt="login" src={'https://lolstatic-a.akamaihd.net/riot-kr/images/parent-portal/login-page.png'} />
          </div>
          <div className={'page-title'}>{t('login.not-login')}</div>
          <div className={'page-label'}>{t('login.guide')}</div>
          <div className={'login'}>
            <Agreement
              title={t('login.agreement.pi.title')}
              text={this.state.piAgreement}
              confirmed={piConfirmed}
              callback={() => this.setState(state => ({ piConfirmed: !state.piConfirmed }))}
            />
            <Notice title={t('login.agreement.pi.consignment.title')} text={this.state.piConsignmentAgreement} />
            <Agreement
              title={t('login.agreement.transfer.title')}
              text={this.state.transferAgreement}
              confirmed={transferConfirmed}
              callback={() => this.setState(state => ({ transferConfirmed: !state.transferConfirmed }))}
            />
            <div className={classNames('error', { 'error-show': message })}>{message}</div>
            <BorderBoxButton className={'button'} click={this.loginWith.bind(this)}>
              {t('login.execute')}
            </BorderBoxButton>
          </div>
          <div className={'inquiries'}>
            <span>{t('login.whatis')}</span>
            <BorderBoxButton className={'button'} click={() => history.push('/faq')}>
              {t('login.faq')}
            </BorderBoxButton>
          </div>
        </div>
      </div>
    );
  }
}

const Agreement = ({ title, text, confirmed, callback }) => {
  return (
    <div>
      <Notice title={title} text={text} />
      <label className={'check'}>
        <input
          type={'checkbox'}
          className={'checkbox'}
          onClick={e => e.stopPropagation()}
          onChange={callback}
          checked={confirmed}
        />
        <span>{t('login.agree')}</span>
      </label>
    </div>
  );
};

const Notice = ({ title, text }) => {
  return (
    <div className="agreement">
      <div className="title">{title}</div>
      <div className="text">
        {text.split('\n').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
